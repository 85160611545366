.App {
  text-align: center;
}

h2 {
  color: darkgray;
  margin-top: 20px;
}

.App-logo {
  height: 30vmin;
  width: 30vmin;
  pointer-events: none;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

.App-header {
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  align-items: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

H4 {
  display: inline;
}
